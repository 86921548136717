var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"support-page"}},[_c('main',[_c('h1',[_vm._v(_vm._s(_vm.$t("SupportPage.Title")))]),_c('h5',[_vm._v(_vm._s(_vm.$t("SupportPage.Subtitle")))]),_c('div',{staticClass:"cards-container"},[_c('Card',{attrs:{"title":_vm.$t('SupportPage.Email'),"subtitle":_vm.$t('SupportPage.SubtitleTeamHelp'),"text-to-clipboard":_vm.info.email.value,"tooltip":{
          copy: _vm.$t('SupportPage.CopyEmail'),
          copied: _vm.$t('SupportPage.Copied'),
        },"toast":{
          title: _vm.$t('SupportPage.EmailCopied'),
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('EmailIcon')]},proxy:true}])}),_c('Card',{attrs:{"title":"WhatsApp","subtitle":_vm.$t('SupportPage.OperationHour'),"text-to-clipboard":_vm.info.whatsapp.value,"tooltip":{
          copy: _vm.$t('SupportPage.CopyNumber'),
          copied: _vm.$t('SupportPage.Copied'),
        },"toast":{
          title: _vm.$t('SupportPage.NumberCopied'),
        },"type":"phone"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('WhatsappIcon')]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }